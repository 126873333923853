<template>
  <div></div>
</template>
<script>
import { submit } from '@/api/base';
import { getDateTime } from '@/utils/tool';
import JsBarcode from 'jsbarcode';
import { screenshotToBase64, botSend, urlToBase64 } from '@/utils/stallBot';

export default {
  name: 'TradePrint',
  props: {
    printViewID: {
      type: String,
      default: '#print-view',
    },
    statusTab: {
      type: String,
      default: 'done',
    },
    printViewTab: {
      type: String,
      required: true,
    },
    printConfig: {
      type: Object,
      required: true,
    },
    a4TotalPage: {
      type: Number,
      default: 0,
    },
    printSource: {
      type: String,
      default: 'print',
    },
  },
  data() {
    return {
      pxPerMm: null,
      printData: [],
      printTime: null,
      pageSize: {
        bill: {
          pageHeight: 172,
          pageWidth: 0,
        },
        a4: {
          totalPage: 0,
          pageHeight: 275,
          pageWidth: 210,
        },
        tag: {
          pageHeight: 0,
          pageWidth: 0,
        },
      },
      paymentQrCode: [],
      adrBase64: ''
    };
  },
  mounted() {
    var that = this;
    that.$nextTick(async () => {
      that.adrBase64 = await urlToBase64('/static/img/adr.png');
      //预加载收款码
      let paymentQRCode = that.$store.state.routes.systemSetting.PaymentQRCode;
      if (paymentQRCode && paymentQRCode.length > 0) {
        for (let i = 0; i < paymentQRCode.length; i++) {
          await urlToBase64(paymentQRCode[i].url).then((res) => {
            that.paymentQrCode.push({
              url: res,
              name: typeof (paymentQRCode[i].name) != 'undefined' && paymentQRCode[i].name != '' ? paymentQRCode[i].name.split('.')[0] : ''
            });
          });
        }
      }
    });
  },
  methods: {
    init(isFirst = true) {
      if (!this.pxPerMm) {
        let $div = this.$('<div/>').css({ position: 'absolute', top: '-1mm', left: '-1mm', height: '1mm', width: '1mm' }).appendTo('body');
        this.pxPerMm = $div.height();
        $div.remove();
      }
      if (this.printConfig.bill) {
        this.pageSize.bill.pageWidth = this.printConfig.bill.pageWidth * this.pxPerMm;
        if (isFirst) {
          this.pageSize.bill.pageHeight = this.pageSize.bill.pageHeight * this.pxPerMm;
        }
      }
      if (this.printConfig.tag) {
        this.pageSize.tag.pageHeight = this.printConfig.tag.pageHeight * this.pxPerMm;
        this.pageSize.tag.pageWidth = this.printConfig.tag.pageWidth * this.pxPerMm;
      }
      if (this.printConfig.a4 && isFirst) {
        this.pageSize.a4.pageHeight = this.pageSize.a4.pageHeight * this.pxPerMm;
        this.pageSize.a4.pageWidth = this.pageSize.a4.pageWidth * this.pxPerMm;
      }
    },
    async getInventoryPrintInfo(inventoryId) {
      var that = this;
      this.$(`${this.printViewID} .paper-bill,${this.printViewID} .paper-a4,${this.printViewID} .paper-tag`).empty();
      that.printData = [];
      const { data } = await submit('/api/wms/inventory/getPrintInfo?t=' + new Date().getTime(), {
        InventoryId: inventoryId,
      });
      that.printData = data;
      this.changePrintView();
    },
    async getAllocatePrintInfo(allocateId) {
      var that = this;
      this.$(`${this.printViewID} .paper-bill,${this.printViewID} .paper-a4,${this.printViewID} .paper-tag`).empty();
      that.printData = [];
      const { data } = await submit('/api/wms/allocate/getPrintInfo?t=' + new Date().getTime(), {
        AllocateID: allocateId,
      });
      that.printData = data;
      this.changePrintView();
    },
    async getPrintInfo(tradeIDs, refundIDs = '', status = null, orderIDs = null, takeStatus = null, batchNos = null) {
      this.$(`${this.printViewID} .paper-bill,${this.printViewID} .paper-a4,${this.printViewID} .paper-tag`).empty();
      this.printData = [];
      if (!tradeIDs && !refundIDs) {
        this.$emit('update:a4-total-page', 0);
        return;
      }
      let orderBySkuCount = false;
      let showSku = true;
      if (this.printViewTab == 'tag') {
        orderBySkuCount = this.printConfig.tag.orderBySkuCountForPrint;
      }
      if (this.printViewTab == 'bill') {
        orderBySkuCount = this.printConfig.bill.orderBySkuCountForPrint;
        showSku = this.printConfig.bill.printType == 1 ? this.printConfig.bill.showSku : true;
      }
      if (this.printViewTab == 'a4' && this.printConfig.a4.printType == 2) {
        orderBySkuCount = this.printConfig.a4.orderBySkuCountForPrint;
      }
      const { data } = await submit('/api/stall/getPrintInfo', {
        tradeIDs: tradeIDs,
        refundIDs: refundIDs,
        orderBySkuCount: orderBySkuCount,
        orderByList: this.printConfig.orderByList,
        showSku: showSku,
        status: status,
        batchNos: batchNos,
        withSupplierGroup: status == 1 ? this.printConfig.withSupplierGroup : false,
        orderBySupplierGroup: this.printConfig.orderBySupplierGroup,
        orderByStoreGroup: this.printConfig.orderByStoreGroup,
        orderIDs: status == 1 && this.printConfig.withSupplierGroup ? orderIDs : null,
        takeStatus: takeStatus
      });
      this.printData = data;
      this.changePrintView();
    },
    changePrintView() {
      if (this.printData.length == 0 || !['bill', 'a4', 'tag'].includes(this.printViewTab) || this.$(`${this.printViewID} .paper-${this.printViewTab} .print-wrapper`).length != 0) {
        return;
      }
      let data = JSON.parse(JSON.stringify(this.printData));
      this.printTime = getDateTime();
      if (this.printViewTab != 'bill' || (this.printSource == 'cashier' && this.printConfig.bill.shieldOutStock)) {
        if (data && data.length > 0) {
          _this = this;
          _this._.remove(data, function (trade) {
            if (trade.Num > trade.OutStockNum) {
              _this._.remove(trade.Items, function (item) {
                if (item.Num > item.OutStockNum) {
                  _this._.remove(item.Specs, function (spec) {
                    if (spec.Num > spec.OutStockNum) {
                      _this._.remove(spec.Sizes, function (size) {
                        return size.Num < 0 ? false : size.Num <= size.OutStockNum;
                      });
                    }
                    return spec.Num < 0 ? false : spec.Num <= spec.OutStockNum;
                  });
                }
                return item.Num < 0 ? false : item.Num <= item.OutStockNum;
              });
            }
            return trade.RefundNum > 0 ? false : trade.Num <= trade.OutStockNum;
          });
        }
      }
      switch (this.printViewTab) {
        case 'bill':
          switch (this.printConfig.bill.printType) {
            case 2:
              this.setBillPage2(data, this.getBillStyle());
              break;
            case 3:
              this.setBillPage3(data, this.getBillStyle3());
              break;
            default:
              this.setBillPage1(data, this.getBillStyle());
              break;
          }
          break;
        case 'a4': {
          let shortPrintTime = this.printTime.substring(0, this.printTime.lastIndexOf(':'));
          switch (this.printConfig.a4.printType) {
            case 2:
              this.setA4Page2(data, shortPrintTime, this.getA4Style2());
              break;
            case 3:
              this.setA4Page3(data, shortPrintTime, this.getA4Style3());
              break;
            case 4:
              this.setA4Page4(data, shortPrintTime, this.getA4Style4());
              break;
            default:
              this.setA4Page1(data, shortPrintTime, this.getA4Style1());
              break;
          }
          break;
        }
        case 'tag':
          if (this.printConfig.tag.showDetail) {
            this.setTagPage(data, this.getTagStyle());
          } else {
            this.setTagPageNoDetail(data, this.getTagStyle());
          }
          break;
      }
      let _this = this;
      this.$(`${this.printViewID} .out-stock-wrapper input`).keyup(function () {
        let $cur = _this.$(this);
        let $otherCur = _this.$(`${this.printViewID} .out-stock-wrapper input[data-purchaser_name="${$cur.attr('data-purchaser_name')}"][data-item_no="${$cur.attr('data-item_no')}"][data-spec_name="${$cur.attr('data-spec_name')}"]`);
        let outStock = $cur.val();
        let oldOutNum = parseInt($cur.attr('data-out_num'));
        outStock = parseInt(outStock);
        if (!outStock || outStock <= 0) {
          outStock = 0;
          $cur.val('');
          $otherCur.val('');
          $cur.removeClass('out-stock');
          $otherCur.removeClass('out-stock');
        } else {
          let num = parseInt($cur.attr('data-num'));
          if (outStock > num) {
            outStock = num;
          }
          $cur.val(outStock);
          $otherCur.val(outStock);
          $cur.addClass('out-stock');
          $otherCur.addClass('out-stock');
        }
        if (oldOutNum == outStock) {
          return;
        }
        let $totalOutNum = _this.$(`${this.printViewID} .total-num span[data-purchaser_name="${$cur.attr('data-purchaser_name')}"]`);
        let totalOutStock = parseInt($totalOutNum.html());
        totalOutStock -= oldOutNum;
        totalOutStock += outStock;
        $cur.attr('data-out_num', outStock);
        $totalOutNum.html(totalOutStock);
      });
    },
    getBillStyle() {
      let style = '<style type="text/css">';
      style += '.print-wrapper-bill{';
      style += `font-size:${this.printConfig.bill.fontSize.detail}px;`;
      style += `width:${this.pageSize.bill.pageWidth}px;`;
      style += 'margin:5px;';
      style += '}';
      style += '.print-wrapper-bill .print-content,.print-wrapper-bill .print-content > div{';
      style += 'background:white;';
      style += 'width:100%;';
      style += '}';
      if (this.printConfig.bill.minHeight) {
        style += '.print-wrapper-bill .print-data{';
        style += `min-height:${this.printConfig.bill.minHeight}mm;`;
        style += '}';
      }
      style += '.print-wrapper-bill .purchaser{';
      style += `font-size:${this.printConfig.bill.fontSize.name}px;`;
      style += 'text-align:center;';
      style += '}';
      style += '.print-wrapper-bill .title{';
      style += 'text-align:center;';
      style += 'min-height:50px;';
      style += 'display:flex;';
      style += 'align-items:center;';
      style += 'justify-content:center;';
      style += `font-size:${this.printConfig.bill.fontSize.remark}px;`;
      style += this.printConfig.bill.remarkPosition == 'header' ? 'font-weight:600;' : '';
      style += '}';
      style += '.print-wrapper-bill .title>div{';
      style += 'padding:5px 0;';
      style += 'display:table-cell;';
      style += 'vertical-align:middle;';
      style += 'word-break:break-all;';
      style += '}';
      style += '.print-wrapper-bill table{';
      style += 'border-collapse:collapse;';
      style += 'text-align:center;';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-bill table th,.print-wrapper-bill table td{';
      style += `font-size:${this.printConfig.bill.fontSize.detail}px;`;
      style += 'padding:0 5px 0 0;';
      style += 'border-bottom:1px solid #303133;';
      style += 'box-sizing:border-box;';
      style += 'vertical-align:baseline;'
      style += '}';
      style += '.print-wrapper-bill table.base th,.print-wrapper-bill table.base td{';
      style += 'padding:2.5px 1px;';
      style += '}';
      style += '.print-wrapper-bill table tr:first-child th{';
      style += 'border-bottom:1px solid #303133;';
      style += 'padding-bottom:5px;';
      style += '}';
      style += '.print-wrapper-bill .item,.print-wrapper-bill .size{';
      style += 'text-align:left;';
      style += '}';
      style += '.print-wrapper-bill .item th:first-child,.print-wrapper-bill .size th,.print-wrapper-bill .item td:first-child,.print-wrapper-bill .size td{';
      style += 'text-align:left;';
      style += '}';
      style += '.print-wrapper-bill .item th,.print-wrapper-bill .item td{';
      style += 'border-bottom:none;';
      style += '}';
      style += '.print-wrapper-bill .size td span{';
      style += 'display:inline-block;';
      style += '}';
      style += '.print-wrapper-bill .out-stock-wrapper{';
      if (this.statusTab != 'waitCheckStock') {
        style += 'visibility:hidden;';
      }
      style += 'color:#dd6161;';
      style += 'margin-left:2px;';
      style += 'font-size:12px;';
      style += 'line-height:12px;';
      style += '}';
      style += '.print-wrapper-bill .out-stock-wrapper input{';
      style += 'width:20px;';
      style += 'height:17px;';
      style += 'border:1px solid #b5b3b3;';
      style += 'border-radius:4px;';
      style += 'text-align:center;';
      style += 'box-sizing:border-box;';
      style += '}';
      style += '.print-wrapper-bill .out-stock-wrapper input:focus{';
      style += 'border:1px solid #409EFF;';
      style += 'outline:none;';
      style += '}';
      style += '.print-wrapper-bill .barcode{';
      style += 'text-align:center;';
      style += '}';
      style += '.print-wrapper-bill .remark{';
      style += 'padding:3px 0;';
      style += '}';
      style += '.print-wrapper-bill .advertisement{';
      style += 'text-align:center;';
      style += '}';
      style += '.print-wrapper-bill .advertisement .icon{';
      style += 'vertical-align: middle;';
      style += 'width:50%;';
      style += '}';
      style += '.print-wrapper-bill .advertisement .img_name{';
      style += 'padding:5px 0px;';
      style += '}';
      style += '.print-wrapper-bill .total-num,.print-wrapper-bill .total-payment{';
      style += `font-size:${this.printConfig.bill.fontSize.statistics}px;`;
      style += 'font-weight:600;';
      style += 'padding:0;';
      style += '&>span{';
      style += 'vertical-align:middle;';
      style += 'display: inline-block;';
      style += '}';
      style += '}';
      style += '.print-wrapper-bill .total-num{';
      style += 'margin-top:20px;';
      style += 'font-weight:500;';
      style += '}';
      style += '.print-wrapper-bill .create-time{';
      style += `font-size:${this.printConfig.bill.fontSize.time}px;`;
      style += 'padding-top:3px;';
      style += '}';
      style += '.print-wrapper-bill .divider{';
      style += 'border-bottom:1px dashed #303133;';
      style += 'margin-top:5px;';
      style += 'margin-bottom:10px;';
      style += '}';
      style += '.print-wrapper-bill .divider:last-child{';
      style += 'border-bottom:unset;';
      style += 'margin-bottom:0;';
      style += '}';
      style += '.print-wrapper-bill .store_name{';
      style += `font-size:${this.printConfig.bill.fontSize.storeName}px;text-align: center;font-weight: bold;`;
      style += '}';
      style += '.print-wrapper-bill .divider2{';
      style += 'border-bottom:2px dashed #6f6f6f;text-align: center;';
      style += '}';
      style += '.print-wrapper-bill .divider2:last-child{';
      style += 'border-bottom:unset;';
      style += '}';
      style += '</style>';
      style += '</style>';
      return style;
    },
    async setBillPage1(data, style) {
      if (!data || data.length == 0) {
        return;
      }
      let $wrapper = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab}"></div>`);
      this.$(`${this.printViewID} .paper-bill`).append($wrapper);
      $wrapper = this.$(`${this.printViewID} .paper-bill .print-wrapper-${this.printViewTab}`);
      $wrapper.append(style);
      $wrapper.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-bill .print-wrapper-${this.printViewTab} .print-content`);
      let preStoreName = '';
      for (let i = 0; i < data.length; i++) {
        let $dataDiv = this.$(`<div class="print-data"></div>`);
        if (data[i].Sender) {
          $dataDiv.attr('bot-type', data[i].Sender.BotType)
            .attr('sender-uid', data[i].Sender.SenderUid)
            .attr('bot-uid', data[i].Sender.BotUid);
        }
        $content.append($dataDiv);
        if (this.printConfig.orderByStoreGroup && data[i].StoreName != '' && preStoreName != data[i].StoreName) {
          preStoreName = data[i].StoreName;
          $dataDiv.append(this.$('<div class="store_name"></div>').text(data[i].StoreName));
          $dataDiv.append(this.$('<div class="divider2"></div>').text('时间：' + this.printTime));
        }
        if (this.printConfig.bill.remarkPosition != 'header') {
          $dataDiv.append(this.$('<div class="purchaser"></div>').text(this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)));
        }
        if (['header', 'top'].includes(this.printConfig.bill.remarkPosition)) {
          let hasBotAccount = false;
          if (this.printConfig.bill.showPreorderAccount && data[i].Sender && data[i].Sender.BotUid != "") {
            let botAccont = data[i].Sender.BotUid;
            if (data[i].Sender.BotName != '') {
              botAccont += "(" + data[i].Sender.BotName + ")";
            }
            $dataDiv.append(this.$('<div class="title"></div>').text(botAccont));
            hasBotAccount = true;
          }
          if (this.printConfig.bill.remark && !hasBotAccount) {
            $dataDiv.append(this.$('<div class="title"></div>').text(this.printConfig.bill.remark));
          }
        }
        if (this.printConfig.bill.remarkPosition == 'header') {
          $dataDiv.append(this.$('<div class="purchaser"></div>').text(this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)));
        }
        let $itemTable = null, $refundTable = null;
        let itemTotalNum = null, itemTotalFee = null, refundNum = null, refundTotalFee = null;
        for (let j = 0; j < data[i].Items.length; j++) {
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let isRefund = false;
              let $headerTr = null;
              if (data[i].Items[j].Specs[m].Sizes[n].Num < 0) {
                isRefund = true;
                if (!$refundTable) {
                  $refundTable = this.$('<table class="base"></table>');
                  $headerTr = this.$('<tr></tr>');
                }
              }
              else if (!$itemTable) {
                $itemTable = this.$('<table class="base"></table>');
                $headerTr = this.$('<tr></tr>');
              }

              let spec = data[i].Items[j].Specs[m].Color;
              if (data[i].Items[j].Specs[m].Sizes[n].Size) {
                spec += (spec ? '-' : '') + data[i].Items[j].Specs[m].Sizes[n].Size;
              }
              let $tr = this.$('<tr></tr>');
              if (this.printConfig.showSupplier) {
                if ($headerTr) {
                  $headerTr.append(`<th>供应商</th>`);
                }
                $tr.append(`<td>${data[i].Items[j].SupplierName || '仓库'}</td>`);
              }
              if ($headerTr) {
                $headerTr.append('<th>货号</th>');
              }
              let $itemTd = this.$(`<td>${data[i].Items[j].ItemNo}</td>`);
              if (this.statusTab == 'waitCheckStock' && !this.printConfig.checkStockBySpec && m == 0 && n == 0) {
                let $input = this.$('<input type="text"></input>')
                  .attr('data-purchaser_name', data[i].PurchaserName)
                  .attr('data-item_no', data[i].Items[j].ItemNo)
                  .attr('data-spec_name', '')
                  .attr('data-num', data[i].Items[j].Num);
                $itemTd.append(this.$(`<span>缺</span>`).addClass('out-stock-wrapper').append($input));
              }
              $tr.append($itemTd);

              if (this.printConfig.bill.showSku) {
                if ($headerTr) {
                  $headerTr.append('<th>规格</th>');
                }
                $tr.append(`<td>${spec}</td>`);
              }
              if ($headerTr) {
                $headerTr.append('<th>订货' + (!this.printConfig.bill.shieldOutStock ? '(缺)' : '') + '</th>');
              }
              let sizeNum = this.printConfig.bill.shieldOutStock ? (data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum) : data[i].Items[j].Specs[m].Sizes[n].Num;
              let $td = this.$(`<td>${sizeNum}</td>`);
              if (this.statusTab == 'waitCheckStock' && this.printConfig.checkStockBySpec) {
                let $input = this.$('<input type="text"></input>')
                  .attr('data-purchaser_name', data[i].PurchaserName)
                  .attr('data-item_no', data[i].Items[j].ItemNo)
                  .attr('data-spec_name', spec)
                  .attr('data-num', data[i].Items[j].Specs[m].Sizes[n].Num);
                $td.append(this.$(`<span>缺</span>`).addClass('out-stock-wrapper').append($input));
              }
              if (this.statusTab == 'done' && data[i].Items[j].Specs[m].Sizes[n].OutStockNum != 0 && !this.printConfig.bill.shieldOutStock) {
                $td.append(`(${data[i].Items[j].Specs[m].Sizes[n].OutStockNum})`);
              }
              $tr.append($td);
              if (this.printConfig.bill.priceType != 'none') {
                if ($headerTr) {
                  $headerTr.append(`<th>${this.printConfig.bill.priceType == 'price' ? '基价' : '指定价'}</th>`);
                }
                $tr.append(`<td>${(this.printConfig.bill.priceType == 'price' ? data[i].Items[j].Specs[m].Sizes[n].Price : data[i].Items[j].Specs[m].Sizes[n].SettlePrice)}</td>`);
              }
              if (this.printConfig.bill.subtotalType != 'none') {
                if ($headerTr) {
                  $headerTr.append(`<th>小计</th>`);
                }
                $tr.append(`<td>${(this.printConfig.bill.subtotalType == 'price' ? data[i].Items[j].Specs[m].Sizes[n].TotalFee : data[i].Items[j].Specs[m].Sizes[n].Payment)}</td>`);
              }
              if (isRefund) {
                refundNum += data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
                refundTotalFee += this.printConfig.bill.subtotalType == 'price' ? data[i].Items[j].Specs[m].Sizes[n].TotalFee : data[i].Items[j].Specs[m].Sizes[n].Payment;
                if ($headerTr) {
                  $refundTable.append($headerTr);
                }
                $refundTable.append($tr);
              }
              else {
                itemTotalNum += data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
                itemTotalFee += this.printConfig.bill.subtotalType == 'price' ? data[i].Items[j].Specs[m].Sizes[n].TotalFee : data[i].Items[j].Specs[m].Sizes[n].Payment;
                if ($headerTr) {
                  $itemTable.append($headerTr);
                }
                $itemTable.append($tr);
              }
            }
          }
        }
        if ($itemTable && $refundTable) {
          $dataDiv.append(this.$('<div style="font-weight:bold;"></div>').text('拿：'));
          let $tr = this.$('<tr></tr>');
          $tr.append(`<td>合计：</td>`);
          if (this.printConfig.showSupplier) {
            $tr.append(`<td></td>`);
          }

          if (this.printConfig.bill.showSku) {
            $tr.append(`<td></td>`);
          }
          $tr.append(`<td>${itemTotalNum}</td>`);
          if (this.printConfig.bill.priceType != 'none') {
            $tr.append(`<td></td>`);
          }
          if (this.printConfig.bill.subtotalType != 'none') {
            $tr.append(`<td>${itemTotalFee}</td>`);
          }
          $itemTable.append($tr);
          $dataDiv.append($itemTable);

          $dataDiv.append(this.$('<div style="font-weight:bold;"></div>').text('退：'));
          $tr = this.$('<tr></tr>');
          $tr.append(`<td>合计：</td>`);
          if (this.printConfig.showSupplier) { $tr.append(`<td></td>`); }
          if (this.printConfig.bill.showSku) { $tr.append(`<td></td>`); }
          $tr.append(`<td>${refundNum}</td>`);
          if (this.printConfig.bill.priceType != 'none') { $tr.append(`<td></td>`); }
          if (this.printConfig.bill.subtotalType != 'none') { $tr.append(`<td>${refundTotalFee}</td>`); }
          $refundTable.append($tr);
          $dataDiv.append($refundTable);
        }
        else if ($itemTable) {
          $dataDiv.append($itemTable);
        }
        else {
          $dataDiv.append($refundTable);
        }

        if (data[i].Remark) {
          $dataDiv.append(this.$('<div class="remark"></div>').append('备注：' + data[i].Remark));
        }
        if (this.printSource == 'cashier') {
          let num = data[i].Num + data[i].RefundNum;
          $dataDiv.append(this.$('<div class="total-num"></div>').append(`${num > 0 && this.printConfig.bill.showReportNum ? `报单数${num} ` : ''}${num > 0 ? `配货数${num - data[i].OutStockNum} ` : ''}${data[i].RefundNum > 0 ? `退货数${data[i].RefundNum}` : ''}`));

          $dataDiv.append(this.$('<div class="total-payment"></div>').append(`<span>合计：${(data[i].Payment + data[i].PostFee)}</span>`)
            .append(`${data[i].PostFee > 0 ? `<span style='font-size:inherit;font-weight:normal;'>（运费${data[i].PostFee}）</span>` : ''}`));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append(`已收：${data[i].ReceivedPayment}`));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append(`待收：${data[i].RestPayment}`));
          if (this.printConfig.bill.showDebtFee == true && data[i].DebtFee && data[i].DebtFee != 0) {
            $dataDiv.append(this.$('<div class="total-payment"></div>').append('累计欠款：' + data[i].DebtFee));
          }
          $dataDiv.append(this.$('<div class="create-time"></div>').append('打印时间：' + this.printTime));
        } else {
          $dataDiv.append(this.$('<div class="total-num"></div>').append(`总数量：${data[i].Num}${data[i].OutStockNum != 0 ? `（${data[i].OutStockNum > 0 ? '缺' : '加'}${Math.abs(data[i].OutStockNum)}）` : ''}`));
          if (this.printConfig.bill.totalAmountType != 'none') {
            $dataDiv.append(this.$('<div class="total-payment"></div>')
              .append(`<span>总金额：${((this.printConfig.bill.totalAmountType == 'price' ? data[i].TotalFee : data[i].Payment) + data[i].PostFee)}</span>`)
              .append(`${data[i].PostFee > 0 ? `<span style='font-size:inherit;font-weight:normal;'>（运费${data[i].PostFee}）</span>` : ''}`));
          }
          if (this.printConfig.bill.showTime || this.printConfig.bill.showTime == undefined) {
            $dataDiv.append(this.$('<div class="create-time"></div>').append('开单时间：' + data[i].CreateTime));
          }
        }
        if (data[i].Barcode && this.printConfig.bill.barcodePosition == 'bottom') {
          $dataDiv.append(this.$('<div class="barcode"><img id="barcode-' + data[i].Barcode + '"/></div>'));
          JsBarcode('#barcode-' + data[i].Barcode, data[i].Barcode, { displayValue: false, width: 1, height: 40, margin: 5 })
        }
        if (this.printConfig.bill.showPaymentQRCode && this.paymentQrCode.length > 0) {
          let qrCodeHtml = this.getPaymentQRCodeHtml(this.paymentQrCode);
          $dataDiv.append(this.$('<div class="advertisement">' + qrCodeHtml + '</div>'));
        }
        if (this.printConfig.bill.remarkPosition == 'bottom') {
          let hasBotAccount = false;
          if (this.printConfig.bill.showPreorderAccount && data[i].Sender && data[i].Sender.BotUid != "") {
            let botAccont = data[i].Sender.BotUid;
            if (data[i].Sender.BotName != '') {
              botAccont += "(" + data[i].Sender.BotName + ")";
            }
            $dataDiv.append(this.$('<div class="title"></div>').text(botAccont));
            hasBotAccount = true;
          }
          if (this.printConfig.bill.remark && !hasBotAccount) {
            $dataDiv.append(this.$('<div class="title"></div>').text(this.printConfig.bill.remark));
          }
        }
        if (this.printSource == 'cashier') {
          $dataDiv.append(this.$(`<div class="advertisement"><img class="icon" src="${this.adrBase64}" /></div>`));
        }
        $content.append(this.$('<div class="divider"></div>'));
      }
    },
    getPaymentQRCodeHtml(urls) {
      let width = this.$store.state.routes.systemSetting.QRCodeWidth;
      let height = this.$store.state.routes.systemSetting.QRCodeHeight;
      let showFileName = this.$store.state.routes.systemSetting.ShowFileName;
      let html = '';
      let customSize = typeof (width) != 'undefined' && typeof (height) != 'undefined' && parseInt(width) > 0 && parseInt(height) > 0;
      let printStyle = 'print-color-adjust: exact !important;color-adjust: exact !important; -webkit-print-color-adjust: exact !important;';
      let defaultStyle = 'width:80%;';
      if (urls.length > 1) {
        defaultStyle = 'width:45%;';
      }
      for (let i = 0; i < urls.length; i++) {
        let urlItem = urls[i];
        html += '<div style="' + (customSize ? 'width:' + width + 'px;' : defaultStyle) + 'display: inline-block;vertical-align: top;' + (i % 2 == 0 ? 'margin-right:5px;' : '') + '">';
        if (customSize) {
          html += '<span style="width:' + width + 'px;height:' + height + 'px;' + 'background-image: url(' + urlItem.url + ');background-size: 100% 100%;background-repeat: no-repeat;background-position: center;display: inline-block;' + printStyle + '"></span>';
        } else {
          html += '<img src="' + urlItem.url + '" style="width:100%;height:auto;' + printStyle + '" />';
        }
        if (typeof (showFileName) != 'undefined' && showFileName == 'Y' && urlItem.name != '') {
          html += '<div class="img_name">' + urlItem.name + '</div>'
        }
        html += '</div>'
      }
      return html;
    },
    async setBillPage2(data, style) {
      if (!data || data.length == 0) {
        return;
      }
      let $wrapper = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab}"></div>`);
      this.$(`${this.printViewID} .paper-bill`).append($wrapper);
      $wrapper = this.$(`${this.printViewID} .paper-bill .print-wrapper-${this.printViewTab}`);
      $wrapper.append(style);
      $wrapper.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-bill .print-wrapper-${this.printViewTab} .print-content`);
      let preStoreName = '';
      for (let i = 0; i < data.length; i++) {
        let $dataDiv = this.$(`<div class="print-data"></div>`);
        if (data[i].Sender) {
          $dataDiv.attr('bot-type', data[i].Sender.BotType)
            .attr('sender-uid', data[i].Sender.SenderUid)
            .attr('bot-uid', data[i].Sender.BotUid);
        }
        $content.append($dataDiv);
        if (this.printConfig.orderByStoreGroup && data[i].StoreName != '' && preStoreName != data[i].StoreName) {
          preStoreName = data[i].StoreName;
          $dataDiv.append(this.$('<div class="store_name"></div>').text(data[i].StoreName));
          $dataDiv.append(this.$('<div class="divider2"></div>').text('时间：' + this.printTime));
        }
        if (this.printConfig.bill.remarkPosition != 'header') {
          $dataDiv.append(this.$('<div class="purchaser"></div>').text(this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)));
        }
        if (['header', 'top'].includes(this.printConfig.bill.remarkPosition)) {
          let hasBotAccount = false;
          if (this.printConfig.bill.showPreorderAccount && data[i].Sender && data[i].Sender.BotUid != "") {
            let botAccont = data[i].Sender.BotUid;
            if (data[i].Sender.BotName != '') {
              botAccont += "(" + data[i].Sender.BotName + ")";
            }
            $dataDiv.append(this.$('<div class="title"></div>').text(botAccont));
            hasBotAccount = true;
          }
          if (this.printConfig.bill.remark && !hasBotAccount) {
            $dataDiv.append(this.$('<div class="title"></div>').text(this.printConfig.bill.remark));
          }
        }
        if (this.printConfig.bill.remarkPosition == 'header') {
          $dataDiv.append(this.$('<div class="purchaser"></div>').text(this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)));
        }
        let $table = this.$('<table></table>');
        let $tr = this.$('<tr class="item"></tr>');

        if (this.printConfig.showSupplier) {
          $tr.append(`<th>供应商</th>`);
        }
        $tr.append('<th>货号</th>');
        $tr.append('<th>规格</th>');
        $table.append($tr);
        for (let j = 0; j < data[i].Items.length; j++) {
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            $tr = this.$('<tr class="item"></tr>');
            if (this.printConfig.showSupplier) {
              $tr.append(`<td>${data[i].Items[j].SupplierName || '仓库'}</td>`);
            }
            let $itemTd = this.$(`<td>${this.printSource == 'cashier' && data[i].Items[j].Num < 0 ? '【退】' : ''}${data[i].Items[j].ItemNo}</td>`);
            if (!this.printConfig.checkStockBySpec && this.statusTab == 'waitCheckStock' && m == 0) {
              let $input = this.$('<input type="text"></input>')
                .attr('data-purchaser_name', data[i].PurchaserName)
                .attr('data-item_no', data[i].Items[j].ItemNo)
                .attr('data-spec_name', '')
                .attr('data-num', data[i].Items[j].Num);
              $itemTd.append(this.$(`<span>缺</span>`).addClass('out-stock-wrapper').append($input));
            }
            $tr.append($itemTd);

            $tr.append(`<td>${data[i].Items[j].Specs[m].Color}</td>`);
            $table.append($tr);
            $tr = this.$('<tr class="size"></tr>');
            let $td = this.$('<td colspan="' + (this.printConfig.showSupplier ? 3 : 2) + '"></td>');
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let num = data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
              $td.append(`<span>${data[i].Items[j].Specs[m].Sizes[n].Size}${Math.abs(num) > 1 ? `*${num}` : ''}</span>`);

              if (this.statusTab == 'waitCheckStock' && this.printConfig.checkStockBySpec) {
                let spec = data[i].Items[j].Specs[m].Color;
                if (data[i].Items[j].Specs[m].Sizes[n].Size) {
                  spec += (spec ? '-' : '') + data[i].Items[j].Specs[m].Sizes[n].Size;
                }
                let $input = this.$('<input type="text"></input>')
                  .attr('data-purchaser_name', data[i].PurchaserName)
                  .attr('data-item_no', data[i].Items[j].ItemNo)
                  .attr('data-spec_name', spec)
                  .attr('data-num', data[i].Items[j].Specs[m].Sizes[n].Num);
                $td.append(this.$(`<span>缺</span>`).addClass('out-stock-wrapper').append($input));
              }
              else if (n < data[i].Items[j].Specs[m].Sizes.length) {
                $td.append(' ');
              }
            }
            $tr.append($td);
            $table.append($tr);
          }
        }
        $dataDiv.append($table);
        if (data[i].Remark) {
          $dataDiv.append(this.$('<div class="remark"></div>').append('备注：' + data[i].Remark));
        }
        if (this.printSource == 'cashier') {
          let num = data[i].Num + data[i].RefundNum;
          $dataDiv.append(this.$('<div class="total-num"></div>').append(`${num > 0 && this.printConfig.bill.showReportNum ? `报单数${num} ` : ''}${num > 0 ? `配货数${num - data[i].OutStockNum} ` : ''}${data[i].RefundNum > 0 ? `退货数${data[i].RefundNum}` : ''}`));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append(`<span>合计：${(data[i].Payment + data[i].PostFee)}</span>`)
            .append(`${data[i].PostFee > 0 ? `<span style='font-size:inherit;font-weight:normal;'>（运费${data[i].PostFee}）</span>` : ''}`));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append('已收：' + data[i].ReceivedPayment));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append('待收：' + data[i].RestPayment));
          if (this.printConfig.bill.showDebtFee == true && data[i].DebtFee && data[i].DebtFee != 0) {
            $dataDiv.append(this.$('<div class="total-payment"></div>').append('累计欠款：' + data[i].DebtFee));
          }
          $dataDiv.append(this.$('<div class="create-time"></div>').append('打印时间：' + this.printTime));
        } else {
          $dataDiv.append(this.$('<div class="total-num"></div>').append(`总数量：${data[i].Num}${data[i].OutStockNum != 0 ? `（${data[i].OutStockNum > 0 ? '缺' : '加'}${Math.abs(data[i].OutStockNum)}）` : ''}`));
          if (this.printConfig.bill.totalAmountType != 'none') {
            $dataDiv.append(this.$('<div class="total-payment"></div>')
              .append(`<span>总金额：${((this.printConfig.bill.totalAmountType == 'price' ? data[i].TotalFee : data[i].Payment) + data[i].PostFee)}</span>`)
              .append(`${data[i].PostFee > 0 ? `<span style='font-size:inherit;font-weight:normal;'>（运费${data[i].PostFee}）</span>` : ''}`));
          }
          if (this.printConfig.bill.showTime || this.printConfig.bill.showTime == undefined) {
            $dataDiv.append(this.$('<div class="create-time"></div>').append('开单时间：' + data[i].CreateTime));
          }
        }
        if (data[i].Barcode && this.printConfig.bill.barcodePosition == 'bottom') {
          $dataDiv.append(this.$('<div class="barcode"><img id="barcode-' + data[i].Barcode + '"/></div>'));
          JsBarcode('#barcode-' + data[i].Barcode, data[i].Barcode, { displayValue: false, width: 1, height: 40, margin: 5 })
        }
        if (this.printConfig.bill.showPaymentQRCode && this.paymentQrCode.length > 0) {
          let qrCodeHtml = this.getPaymentQRCodeHtml(this.paymentQrCode);
          $dataDiv.append(this.$('<div class="advertisement">' + qrCodeHtml + '</div>'));
        }
        if (this.printConfig.bill.remarkPosition == 'bottom') {
          let hasBotAccount = false;
          if (this.printConfig.bill.showPreorderAccount && data[i].Sender && data[i].Sender.BotUid != "") {
            let botAccont = data[i].Sender.BotUid;
            if (data[i].Sender.BotName != '') {
              botAccont += "(" + data[i].Sender.BotName + ")";
            }
            $dataDiv.append(this.$('<div class="title"></div>').text(botAccont));
            hasBotAccount = true;
          }
          if (this.printConfig.bill.remark && !hasBotAccount) {
            $dataDiv.append(this.$('<div class="title"></div>').text(this.printConfig.bill.remark));
          }
        }
        if (this.printSource == 'cashier') {
          $dataDiv.append(this.$(`<div class="advertisement"><img class="icon" src="${this.adrBase64}" /></div>`));
        }
        $content.append(this.$('<div class="divider"></div>'));
      }
    },
    getBillStyle3() {
      let style = '<style type="text/css">';
      style += '.print-wrapper-bill{';
      style += `font-size:${this.printConfig.bill.fontSize.detail}px;`;
      style += `width:${this.pageSize.bill.pageWidth}px;`;
      style += 'margin:5px;';
      style += '}';
      style += '.print-wrapper-bill .print-content,.print-wrapper-bill .print-content > div{';
      style += 'background:white;';
      style += 'width:100%;';
      style += '}';
      if (this.printConfig.bill.minHeight) {
        style += '.print-wrapper-bill .print-data{';
        style += `min-height:${this.printConfig.bill.minHeight}mm;`;
        style += '}';
      }
      style += '.print-wrapper-bill .purchaser{';
      style += `font-size:${this.printConfig.bill.fontSize.name}px;`;
      style += 'text-align:center;';
      style += '}';
      style += '.print-wrapper-bill .title{';
      style += 'text-align:center;';
      style += 'min-height:40px;';
      style += 'display:flex;';
      style += 'align-items:center;';
      style += 'justify-content:center;';
      style += `font-size:${this.printConfig.bill.fontSize.remark}px;`;
      style += this.printConfig.bill.remarkPosition == 'header' ? 'font-weight:600;' : '';
      style += '}';
      style += '.print-wrapper-bill .title>div{';
      style += 'padding:5px 0;';
      style += 'display:table-cell;';
      style += 'vertical-align:middle;';
      style += 'word-break:break-all;';
      style += '}';
      style += '.print-wrapper-bill table{';
      style += 'border-collapse:collapse;';
      style += 'text-align:center;';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-bill table td{';
      style += `font-size:${this.printConfig.bill.fontSize.detail}px;`;
      style += 'padding:0 5px 0 0;';
      style += 'box-sizing:border-box;';
      style += 'vertical-align:baseline;'
      style += 'text-align:left;';
      style += 'word-break:break-all;';
      style += '}';
      style += '.print-wrapper-bill table td.size{';
      style += 'white-space:break-spaces !important;';
      style += '}';
      style += '.print-wrapper-bill table td span{';
      style += 'display:inline-block;';
      style += '}';
      style += '.print-wrapper-bill table td:not(.print-wrapper-bill table td:last-child){';
      style += 'white-space:nowrap;';
      style += '}';
      style += '.print-wrapper-bill .out-stock-wrapper{';
      if (this.statusTab != 'waitCheckStock') {
        style += 'visibility:hidden;';
      }
      style += 'color:#dd6161;';
      style += 'margin-left:2px;';
      style += 'font-size:12px;';
      style += 'line-height:12px;';
      style += '}';
      style += '.print-wrapper-bill .out-stock-wrapper input{';
      style += 'width:20px;';
      style += 'height:17px;';
      style += 'border:1px solid #b5b3b3;';
      style += 'border-radius:4px;';
      style += 'text-align:center;';
      style += 'box-sizing:border-box;';
      style += '}';
      style += '.print-wrapper-bill .out-stock-wrapper input:focus{';
      style += 'border:1px solid #409EFF;';
      style += 'outline:none;';
      style += '}';
      style += '.print-wrapper-bill .barcode{';
      style += 'text-align:center;';
      style += '}';
      style += '.print-wrapper-bill .remark{';
      style += 'padding:3px 0;';
      style += '}';
      style += '.print-wrapper-bill .advertisement{';
      style += 'text-align:center;';
      style += '}';
      style += '.print-wrapper-bill .advertisement .icon{';
      style += 'vertical-align: middle;';
      style += 'width:50%;';
      style += '}';
      style += '.print-wrapper-bill .advertisement .img_name{';
      style += 'padding:5px 0px;';
      style += '}';
      style += '.print-wrapper-bill .total-num,.print-wrapper-bill .total-payment{';
      style += `font-size:${this.printConfig.bill.fontSize.statistics}px;`;
      style += 'font-weight:600;';
      style += 'padding:0;';
      style += '&>span{';
      style += 'vertical-align:middle;';
      style += 'display: inline-block;';
      style += '}';
      style += '}';
      style += '.print-wrapper-bill .total-num{';
      style += 'font-weight:600;';
      style += '}';
      style += '.print-wrapper-bill .bill{';
      style += 'text-align:right;';
      style += 'padding-right:5px;';
      style += '}';
      style += '.print-wrapper-bill .total-num span:first-child{';
      style += 'margin-right:10px;';
      style += '}';
      style += '.print-wrapper-bill .create-time{';
      style += `font-size:${this.printConfig.bill.fontSize.time}px;`;
      style += 'padding:3px 0 5px 0;';
      style += '}';
      style += '.print-wrapper-bill .divider{';
      style += 'border-bottom:1px dashed #303133;';
      style += '}';
      style += '.print-wrapper-bill .divider:last-child{';
      style += 'border-bottom:unset;';
      style += '}';
      style += '.print-wrapper-bill .store_name{';
      style += `font-size:${this.printConfig.bill.fontSize.storeName}px;text-align: center;font-weight: bold;`;
      style += '}';
      style += '.print-wrapper-bill .divider2{';
      style += 'border-bottom:2px dashed #6f6f6f;text-align: center;';
      style += '}';
      style += '.print-wrapper-bill .divider2:last-child{';
      style += 'border-bottom:unset;';
      style += '}';
      style += '.spuNumTd {';
      style += 'text-align:right !important;';
      style += `${this.printConfig.bill.showSpuNum ? "" : "display:none;"}`;
      style += '}';
      style += '</style>';
      return style;
    },
    async setBillPage3(data, style) {
      if (!data || data.length == 0) {
        return;
      }
      let $wrapper = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab}"></div>`);
      this.$(`${this.printViewID} .paper-bill`).append($wrapper);
      $wrapper = this.$(`${this.printViewID} .paper-bill .print-wrapper-${this.printViewTab}`);
      $wrapper.append(style);
      $wrapper.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-bill .print-wrapper-${this.printViewTab} .print-content`);
      let preStoreName = '';
      for (let i = 0; i < data.length; i++) {
        let $dataDiv = this.$(`<div class="print-data"></div>`);
        if (data[i].Sender) {
          $dataDiv.attr('bot-type', data[i].Sender.BotType)
            .attr('sender-uid', data[i].Sender.SenderUid)
            .attr('bot-uid', data[i].Sender.BotUid);
        }
        $content.append($dataDiv);
        if (this.printConfig.orderByStoreGroup && data[i].StoreName != '' && preStoreName != data[i].StoreName) {
          preStoreName = data[i].StoreName;
          $dataDiv.append(this.$('<div class="store_name"></div>').text(data[i].StoreName));
          $dataDiv.append(this.$('<div class="divider2"></div>').text('时间：' + this.printTime));
        }
        if (this.printConfig.bill.remarkPosition != 'header') {
          $dataDiv.append(this.$('<div class="purchaser"></div>').text(this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)));
        }
        if (['header', 'top'].includes(this.printConfig.bill.remarkPosition)) {
          let hasBotAccount = false;
          if (this.printConfig.bill.showPreorderAccount && data[i].Sender && data[i].Sender.BotUid != "") {
            let botAccont = data[i].Sender.BotUid;
            if (data[i].Sender.BotName != '') {
              botAccont += "(" + data[i].Sender.BotName + ")";
            }
            $dataDiv.append(this.$('<div class="title"></div>').text(botAccont));
            hasBotAccount = true;
          }
          if (this.printConfig.bill.remark && !hasBotAccount) {
            $dataDiv.append(this.$('<div class="title"></div>').text(this.printConfig.bill.remark));
          }
        }
        if (this.printConfig.bill.remarkPosition == 'header') {
          $dataDiv.append(this.$('<div class="purchaser"></div>').text(this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)));
        }
        let $table = this.$('<table></table>');
        for (let j = 0; j < data[i].Items.length; j++) {
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            let $tr = this.$('<tr class="item"></tr>');
            if (this.printConfig.showSupplier) {
              $tr.append(this.$(`<td>${data[i].Items[j].SupplierName || '仓库'}</td>`))
            }
            let $itemTd = this.$(`<td>${this.printSource == 'cashier' && data[i].Items[j].Num < 0 ? '【退】' : ''}${data[i].Items[j].ItemNo}</td>`);

            if (!this.printConfig.checkStockBySpec && m == 0 && this.statusTab == 'waitCheckStock') {
              let $input = this.$('<input type="text"></input>')
                .attr('data-purchaser_name', data[i].PurchaserName)
                .attr('data-item_no', data[i].Items[j].ItemNo)
                .attr('data-spec_name', '')
                .attr('data-num', data[i].Items[j].Num);
              $itemTd.append(this.$(`<span>缺</span>`).addClass('out-stock-wrapper').append($input));
            }
            $tr.append($itemTd);

            $tr.append(`<td>${data[i].Items[j].Specs[m].Color}</td>`);
            let $td = this.$('<td class="size"></td>');
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let num = data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
              $td.append(`<span>${data[i].Items[j].Specs[m].Sizes[n].Size}${Math.abs(num) > 1 ? `*${num}` : ''}</span>`);

              if (this.printConfig.checkStockBySpec && this.statusTab == 'waitCheckStock') {
                let spec = data[i].Items[j].Specs[m].Color;
                if (data[i].Items[j].Specs[m].Sizes[n].Size) {
                  spec += (spec ? '-' : '') + data[i].Items[j].Specs[m].Sizes[n].Size;
                }
                let $input = this.$('<input type="text"></input>')
                  .attr('data-purchaser_name', data[i].PurchaserName)
                  .attr('data-item_no', data[i].Items[j].ItemNo)
                  .attr('data-spec_name', spec)
                  .attr('data-num', data[i].Items[j].Specs[m].Sizes[n].Num);
                $td.append(this.$(`<span>缺</span>`).addClass('out-stock-wrapper').append($input));
              }
              else if (n < data[i].Items[j].Specs[m].Sizes.length) {
                $td.append(' ');
              }
            }
            $tr.append($td);
            $tr.append(`<td class='spuNumTd'>${data[i].Items[j].Specs[m].Num}双</td>`);
            $table.append($tr);
          }
        }
        $dataDiv.append($table);
        if (data[i].Remark) {
          $dataDiv.append(this.$('<div class="remark"></div>').append('备注：' + data[i].Remark));
        }
        if (this.printSource == 'cashier') {
          let num = data[i].Num + data[i].RefundNum;
          $dataDiv.append(this.$('<div class="total-num"></div>').append(`${num > 0 && this.printConfig.bill.showReportNum ? `报单数${num} ` : ''}${num > 0 ? `配货数${num - data[i].OutStockNum} ` : ''}${data[i].RefundNum > 0 ? `退货数${data[i].RefundNum}` : ''}`));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append(`<span>合计：${(data[i].Payment + data[i].PostFee)}</span>`)
            .append(`${data[i].PostFee > 0 ? `<span style='font-size:inherit;font-weight:normal;'>（运费${data[i].PostFee}）</span>` : ''}`));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append('已收：' + data[i].ReceivedPayment));
          $dataDiv.append(this.$('<div class="total-payment"></div>').append('待收：' + data[i].RestPayment));
          if (this.printConfig.bill.showDebtFee == true && data[i].DebtFee && data[i].DebtFee != 0) {
            $dataDiv.append(this.$('<div class="total-payment"></div>').append('累计欠款：' + data[i].DebtFee));
          }
          $dataDiv.append(this.$('<div class="create-time"></div>').append('打印时间：' + this.printTime));
        } else {
          let $totalDiv = this.$('<div class="total-num bill"></div>');
          let $totalPayment = null;
          $totalDiv.append(`<span>小计：${data[i].Num}${data[i].OutStockNum != 0 ? `（${data[i].OutStockNum > 0 ? '缺' : '加'}${Math.abs(data[i].OutStockNum)}）` : ''}</span>`);
          if (this.printConfig.bill.totalAmountType != 'none') {
            if (data[i].PostFee > 0) {
              $totalPayment = this.$('<div class="total-payment"></div>')
                .append(`<span>总价：${((this.printConfig.bill.totalAmountType == 'price' ? data[i].TotalFee : data[i].Payment) + data[i].PostFee)}</span>`)
                .append(`${data[i].PostFee > 0 ? `<span style='font-size:inherit;font-weight:normal;'>（运费${data[i].PostFee}）</span>` : ''}`);
            }
            else {
              $totalDiv.append(`<span>总价：${((this.printConfig.bill.totalAmountType == 'price' ? data[i].TotalFee : data[i].Payment) + data[i].PostFee)}</span>`);
            }
          }
          $dataDiv.append($totalDiv);
          if ($totalPayment) {
            $dataDiv.append($totalPayment);
          }
          if (this.printConfig.bill.showTime || this.printConfig.bill.showTime == undefined) {
            $dataDiv.append(this.$('<div class="create-time"></div>').append('开单时间：' + data[i].CreateTime));
          }
        }
        if (data[i].Barcode && this.printConfig.bill.barcodePosition == 'bottom') {
          $dataDiv.append(this.$('<div class="barcode"><img id="barcode-' + data[i].Barcode + '"/></div>'));
          JsBarcode('#barcode-' + data[i].Barcode, data[i].Barcode, { displayValue: false, width: 1, height: 40, margin: 5 })
        }
        if (this.printConfig.bill.showPaymentQRCode && this.paymentQrCode.length > 0) {
          let qrCodeHtml = this.getPaymentQRCodeHtml(this.paymentQrCode);
          $dataDiv.append(this.$('<div class="advertisement">' + qrCodeHtml + '</div>'));
        }
        if (this.printConfig.bill.remarkPosition == 'bottom') {
          let hasBotAccount = false;
          if (this.printConfig.bill.showPreorderAccount && data[i].Sender && data[i].Sender.BotUid != "") {
            let botAccont = data[i].Sender.BotUid;
            if (data[i].Sender.BotName != '') {
              botAccont += "(" + data[i].Sender.BotName + ")";
            }
            $dataDiv.append(this.$('<div class="title"></div>').text(botAccont));
            hasBotAccount = true;
          }
          if (this.printConfig.bill.remark && !hasBotAccount) {
            $dataDiv.append(this.$('<div class="title"></div>').text(this.printConfig.bill.remark));
          }
        }
        if (this.printSource == 'cashier') {
          $dataDiv.append(this.$(`<div class="advertisement"><img class="icon" src="${this.adrBase64}" /></div>`));
        }
        $content.append(this.$('<div class="divider"></div>'));
      }
    },
    getA4Style1() {
      let style = '<style type="text/css">';
      style += '.print-wrapper-a4{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += `height:${this.pageSize.a4.pageHeight}px;`;
      style += `width:${this.pageSize.a4.pageWidth}px;`;
      style += 'background:white;';
      style += 'margin:5px;';
      style += 'page-break-after:always;';
      style += '}';
      style += '.print-wrapper-a4 .store-name{';
      style += `font-size:${this.printConfig.a4.fontSize.storeName}px;`;
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'width:calc(100% - 10px);';
      style += 'margin:0 5px;';
      style += 'padding:5px 0;';
      style += '}';
      style += '.print-wrapper-a4 .print-content > div,.print-wrapper-a4 .print-content table{';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-a4 .title{';
      style += 'text-align:center;';
      style += 'position:absolute;';
      style += 'font-size:16px;';
      style += '}';
      style += '.print-wrapper-a4 .print-top{';
      style += 'text-align:left;';
      style += 'color:#565656;';
      style += 'margin-bottom:10px;';
      style += '}';
      style += '.print-wrapper-a4 .purchaser{';
      style += `font-size:${this.printConfig.a4.fontSize.name}px;`;
      style += 'font-weight:600;';
      style += '}';
      style += '.print-wrapper-a4 td{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += 'vertical-align:top;';
      style += 'word-break:break-word;';
      style += 'padding:0 8px 0 0;';
      style += '}';
      style += '.print-wrapper-a4 td:last-child{';
      style += 'padding-right:0;';
      style += '}';
      style += '.print-wrapper-a4 td.item-no{';
      style += `width:${this.printConfig.a4.itemNoWidth}px;`;
      style += '}';
      style += '.print-wrapper-a4 td.color{';
      style += `width:${this.printConfig.a4.colorWidth}px;`;
      style += '}';
      style += '.print-wrapper-a4 td .size{';
      style += 'display:inline-block;';
      style += '}';
      style += '.print-wrapper-a4 td.settle-price{';
      style += 'width:60px;';
      style += `font-size:${this.printConfig.a4.fontSize.price}px;`;
      style += 'font-weight:600;';
      style += '}';
      style += '.print-wrapper-a4 td.payment{';
      style += 'width:80px;';
      style += `font-size:${this.printConfig.a4.fontSize.price}px;`;
      style += 'font-weight:600;';
      style += '}';
      style += '.print-wrapper-a4 .out-stock-wrapper{';
      if (this.statusTab != 'waitCheckStock') {
        style += 'visibility:hidden;';
      }
      style += 'color:#dd6161;';
      style += 'margin-left:2px;';
      style += 'font-size:12px;';
      style += 'line-height:12px;';
      style += '}';
      style += '.print-wrapper-a4 .out-stock-wrapper input{';
      style += 'width:20px;';
      style += 'height:17px;';
      style += 'border:1px solid #b5b3b3;';
      style += 'border-radius:4px;';
      style += 'text-align:center;';
      style += 'box-sizing:border-box;';
      style += '}';
      style += '.print-wrapper-a4 .out-stock-wrapper input:focus{';
      style += 'border:1px solid #409EFF;';
      style += 'outline:none;';
      style += '}';
      style += '.print-wrapper-a4 td.total-num{';
      style += 'padding-top:8px;';
      style += '.total-out-num{';
      style += 'padding-left:8px;';
      style += 'color:#dd6161;';
      style += '}';
      style += '}';
      style += '.print-wrapper-a4 td.total-num,.print-wrapper-a4 td.total-payment{';
      style += `font-size:${this.printConfig.a4.fontSize.statistics}px;`;
      style += 'font-weight:600;';
      style += '}';
      style += '.print-wrapper-a4 hr{';
      style += 'border-top:none;';
      style += 'border-color:#9a9797;';
      style += '}';
      style += '</style>';
      return style;
    },
    setA4Page1(data, printTime, style, curPage = 1, showPurchaser = true, preStoreName = '') {
      if (!data || data.length == 0) {
        return;
      }
      if (this.printConfig.orderByStoreGroup && preStoreName != data[0].StoreName) {
        preStoreName = data[0].StoreName;
      }
      this.pageSize.a4.totalPage = curPage == 1 ? 1 : this.pageSize.a4.totalPage + 1;
      let hasNext = false;
      let $wrapper = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab} print-wrapper-${curPage}"></div>`);
      this.$(`${this.printViewID} .paper-a4`).append($wrapper);
      $wrapper = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage}`);
      $wrapper.append(style);
      $wrapper.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage} .print-content`);
      if (this.printConfig.a4.title) {
        $content.append(`<div class="title">${this.printConfig.a4.title}</div>`);
      }
      $content.append(`<div class="print-top">${curPage}/<span class="total-page"></span>&nbsp;&nbsp;&nbsp;<span class="print-time">${printTime}</span>${preStoreName != '' ? `&nbsp;&nbsp;&nbsp;<span class="store-name">${preStoreName}</span>` : ''}</div>`);
      $content.append('<table></table>');
      let $table = $content.find('table');
      let index = 0;
      for (let i = 0; i < data.length; i++) {
        if (this.printConfig.orderByStoreGroup && data[i].StoreName != preStoreName) {
          hasNext = true;
          break;
        }
        index++;
        let colspan = (this.printConfig.a4.showSettlePrice ? 5 : 3) + (this.printConfig.showSupplier ? 1 : 0);
        if (showPurchaser) {
          let $tr = this.$(`<tr class="purchaser"><td colspan="${curPage == 1 && index == 1 && this.printConfig.a4.showSettlePrice ? colspan - 2 : colspan}">${this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)}</td></tr>`);
          if (curPage == 1 && index == 1 && this.printConfig.a4.showSettlePrice) {
            $tr.append('<td class="settle-price">单价</td>');
            $tr.append('<td class="payment">总金额</td>');
          }
          showPurchaser = false;
          $table.append($tr);
          hasNext = this.checkOverA4Page($table, $content);
          if (hasNext) {
            break;
          }
        }
        for (let j = 0; j < data[i].Items.length; j++) {
          let itemIndex = 0;
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            let size = '';
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let num = data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
              size += (n > 0 ? ' ' : '') + '<span class="size">';
              size += data[i].Items[j].Specs[m].Sizes[n].Size;
              if (num > 1) {
                if (data[i].Items[j].Specs[m].Sizes[n].Size) {
                  size += '*';
                }
                size += num;
              }
              if (this.printConfig.checkStockBySpec && (this.statusTab == 'waitCheckStock' || (!this.printConfig.skipCheckStock && this.statusTab == 'waitPrint'))) {
                let spec = data[i].Items[j].Specs[m].Color;
                if (spec && data[i].Items[j].Specs[m].Sizes[n].Size) {
                  spec += '-';
                }
                spec += data[i].Items[j].Specs[m].Sizes[n].Size;
                size += `<span class="out-stock-wrapper">缺`;
                size += `<input type="text" data-purchaser_name="${data[i].PurchaserName}" data-item_no="${data[i].Items[j].ItemNo}" data-spec_name="${spec}" data-num="${num}" data-out_num="${data[i].Items[j].Specs[m].Sizes[n].OutStockNum}"/>`;
                size += '</span>';
              }
              size += '</span>';
            }
            let $tr = this.$(`<tr></tr>`);
            if (itemIndex == 0) {
              if (this.printConfig.showSupplier) {
                $tr.append(this.$(`<td rowspan="${data[i].Items[j].Specs.length}">${data[i].Items[j].SupplierName || '仓库'}</td>`));
              }
              let itemNo = `<td rowspan="${data[i].Items[j].Specs.length}" class="item-no">${data[i].Items[j].ItemNo}`;
              if (!this.printConfig.checkStockBySpec) {
                let num = data[i].Items[j].Num - data[i].Items[j].OutStockNum;
                itemNo += `<span class="out-stock-wrapper">缺`;
                itemNo += `<input type="text" data-purchaser_name="${data[i].PurchaserName}" data-item_no="${data[i].Items[j].ItemNo}" data-spec_name="" data-num="${num}" data-out_num="${data[i].Items[j].OutStockNum}"/>`;
                itemNo += '</span>';
              }
              itemNo += '</td>';
              $tr.append(itemNo);
            }
            $tr.append(`<td class="color">${data[i].Items[j].Specs[m].Color}</td>`);
            $tr.append(`<td>${size}</td>`);
            if (itemIndex++ == 0 && this.printConfig.a4.showSettlePrice) {
              $tr.append(`<td rowspan="${data[i].Items[j].Specs.length}" class="settle-price">￥${data[i].Items[j].SettlePrice}</td>`);
              $tr.append(`<td rowspan="${data[i].Items[j].Specs.length}" class="payment">￥${data[i].Items[j].Payment}</td>`);
            }
            $table.append($tr);
            hasNext = this.checkOverA4Page($table, $content);
            if (hasNext) {
              break;
            }
            data[i].Items[j].Specs.splice(m, 1);
            m--;
          }
          if (hasNext) {
            break;
          }
          data[i].Items.splice(j, 1);
          j--;
        }
        if (hasNext) {
          break;
        }
        if (data[i].Remark) {
          $table.append(`<tr><td colspan="${colspan}">备注：${data[i].Remark}</td></tr>`);
          hasNext = this.checkOverA4Page($table, $content);
          if (hasNext) {
            break;
          }
          data[i].Remark = null;
        }
        if (!data[i].showTotalNum) {
          $table.append(`<tr><td colspan="${colspan}" class="total-num">总数量：${data[i].Num - data[i].OutStockNum}<span class="total-out-num">${this.statusTab == 'waitPrint' ? '' : ('总缺货：<span data-purchaser_name="' + data[i].PurchaserName + '">' + data[i].OutStockNum + '</span>')}</span></td></tr>`);
          hasNext = this.checkOverA4Page($table, $content);
          if (hasNext) {
            break;
          }
          data[i].showTotalNum = true;
        }
        if (this.printConfig.a4.showSettlePrice) {
          if (!data[i].showTotalPayment) {
            $table.append(`<tr><td colspan="${colspan}" class="total-payment">总金额：${data[i].Payment}${data[i].PostFee > 0 ? `<span style='font-size:inherit;font-weight:normal;'>（运费${data[i].PostFee}）</span>` : ''}</td></tr>`);
            hasNext = this.checkOverA4Page($table, $content);
            if (hasNext) {
              break;
            }
            data[i].showTotalPayment = true;
          }
        }
        if (i < data.length - 1) {
          $table.append(`<tr><td colspan="${colspan}"><hr/></td></tr>`);
          hasNext = this.checkOverA4Page($table, $content);
          if (hasNext) {
            break;
          }
        }
        data.splice(i, 1);
        i--;
        showPurchaser = true;
      }
      if (hasNext) {
        this.setA4Page1(data, printTime, style, curPage + 1, showPurchaser, preStoreName);
      } else {
        this.$(`${this.printViewID} .paper-a4 .total-page`).text(this.pageSize.a4.totalPage);
        this.$emit('update:a4-total-page', this.pageSize.a4.totalPage);
      }
    },
    getA4Style2() {
      let style = '<style type="text/css">';
      style += '.print-wrapper-a4{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += `height:${this.pageSize.a4.pageHeight}px;`;
      style += `width:${this.pageSize.a4.pageWidth}px;`;
      style += 'background:white;';
      style += 'margin:5px;';
      style += 'page-break-after:always;';
      style += '}';
      style += '.print-wrapper-a4 .store-name{';
      style += `font-size:${this.printConfig.a4.fontSize.storeName}px;`;
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'width:calc(100% - 10px);';
      style += 'margin:0 5px;';
      style += 'padding:5px 0;';
      style += '}';
      style += '.print-wrapper-a4 .print-content > div,.print-wrapper-a4 .print-content table{';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-a4 .title{';
      style += 'text-align:center;';
      style += 'font-size:18px;';
      style += '}';
      style += '.print-wrapper-a4 table{';
      style += 'border-collapse:collapse;';
      style += '}';
      style += '.print-wrapper-a4 tr:first-child td{';
      style += 'text-align:center;';
      style += 'font-size:18px;';
      style += 'font-weight:600;';
      style += 'line-height:26px;';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-a4 td{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += 'vertical-align:middle;';
      style += 'word-break:break-word;';
      style += 'padding:2px 5px;';
      style += 'border:1px solid;';
      style += 'width:265px;';
      style += `line-height:${parseInt(this.printConfig.a4.fontSize.detail) + 2}px;`;
      style += '}';
      style += '.print-wrapper-a4 td .size{';
      style += 'display:inline-block;';
      style += '}';
      style += '.print-wrapper-a4 .page-container{';
      style += 'position:absolute;';
      style += 'top:0;';
      style += 'left:0;';
      style += '}';
      style += '.print-wrapper-a4 .purchaser{';
      style += `font-weight:600;`;
      style += `font-size:${this.printConfig.a4.fontSize.name}px;`;
      style += '}';
      style += '.print-wrapper-a4 .item-no,.print-wrapper-a4 td .size{';
      style += `font-weight:600;`;
      style += '}';
      style += '</style>';
      return style;
    },
    setA4Page2(data, printTime, style, curPage = 1, preStoreName = '') {
      if (!data || data.length == 0) {
        return;
      }
      if (curPage == 1) {
        printTime = printTime.split(' ')[0];
      }
      if (this.printConfig.orderByStoreGroup && preStoreName != data[0].StoreName) {
        preStoreName = data[0].StoreName;
      }
      this.pageSize.a4.totalPage = curPage == 1 ? 1 : this.pageSize.a4.totalPage + 1;
      let hasNext = false;
      let $wrapper = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab} print-wrapper-${curPage}"></div>`);
      this.$(`${this.printViewID} .paper-a4`).append($wrapper);
      $wrapper = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage}`);
      $wrapper.append(style);
      $wrapper.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage} .print-content`);
      if (this.printConfig.a4.title) {
        $content.append(`<div class="title">${this.printConfig.a4.title}</div>`);
      }
      $content.append('<table></table>');
      let $table = $content.find('table');
      $table.append(`<tr><td colspan="4"><span class="print-time">${printTime}</span>${preStoreName != '' ? `&nbsp;&nbsp;&nbsp;<span class="store-name">${preStoreName}</span>` : ''}&nbsp;&nbsp;<span class="page-container">${curPage}/<span class="total-page"></span></span></td></tr>`);
      let index = 0;
      let $tr = this.$('<tr></tr>');
      for (let i = 0; i < data.length; i++) {
        index++;
        let $td = this.$('<td></td>');
        let content = `<div class="purchaser">${this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)}</div>`;
        for (let j = 0; j < data[i].Items.length; j++) {
          let supplierStr = '';
          if (this.printConfig.showSupplier) {
            supplierStr = `<span style='font-weight:normal;'>(${data[i].Items[j].SupplierName || '仓库'})</span>`;
          }
          content += `${j > 0 ? ' ' : ''}<span class="item-no">${supplierStr}${data[i].Items[j].ItemNo}</span>`;
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            content += `${m > 0 ? ' ' : ''}<span class="color">${data[i].Items[j].Specs[m].Color}</span>`;
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let size = data[i].Items[j].Specs[m].Sizes[n].Size;
              let num = data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
              if (num > 1) {
                size += '*' + num;
              }
              content += `${n > 0 ? ' ' : ''}<span class="size">${size}</span>`;
            }
          }
        }
        $td.append(content);
        if (data[i].Remark) {
          $td.append('<br/>').append(`备注：${data[i].Remark}`);
        }
        if (this.printConfig.a4.showSettlePrice) {
          $td.append('<br/>').append(`<strong>合计：${data[i].Payment}(${data[i].Num}件)</strong>`);
        }
        $tr.append($td);
        let nextIdx = i + 1;
        if (index == 4 || i == data.length - 1) {
          if (index < 4) {
            for (let j = 0; j < 4 - index; j++) {
              $tr.append('<td></td>');
            }
          }
          $table.append($tr);
          hasNext = this.checkOverA4Page($table, $content);
          if (hasNext) {
            break;
          }
          for (let j = 1; j <= index; j++) {
            data.splice(0, 1);
          }
          i = -1;
          index = 0;
          $tr = this.$('<tr></tr>');
        }
        else if (nextIdx < (data.length - 1) && this.printConfig.orderByStoreGroup && data[nextIdx].StoreName != preStoreName) {
          hasNext = true;
          if (index < 4) {
            for (let j = 0; j < 4 - index; j++) {
              $tr.append('<td></td>');
            }
          }
          $table.append($tr);
          for (let x = 0; x < nextIdx; x++) {
            data.splice(0, 1);
          }
          break;
        }
      }
      if (hasNext) {
        this.setA4Page2(data, printTime, style, curPage + 1, preStoreName);
      } else {
        this.$(`${this.printViewID} .paper-a4 .total-page`).text(this.pageSize.a4.totalPage);
        this.$emit('update:a4-total-page', this.pageSize.a4.totalPage);
      }
    },
    getA4Style3() {
      let style = '<style type="text/css">';
      style += '.print-wrapper-a4{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += `height:${this.pageSize.a4.pageHeight}px;`;
      style += `width:${this.pageSize.a4.pageWidth}px;`;
      style += 'background:white;';
      style += 'margin:5px;';
      style += 'page-break-after:always;';
      style += '}';
      style += '.print-wrapper-a4 .store-name{';
      style += `font-size:${this.printConfig.a4.fontSize.storeName}px;`;
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'width:calc(100% - 10px);';
      style += 'margin:0 5px;';
      style += 'padding:5px 0;';
      style += '}';
      style += '.print-wrapper-a4 .print-content > div,.print-wrapper-a4 .print-content table{';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-a4 .title{';
      style += 'text-align:center;';
      style += 'font-size:16px;';
      style += '}';
      style += '.print-wrapper-a4 .print-top{';
      style += 'text-align:center;';
      style += 'font-size:15px;';
      style += 'margin-bottom:10px;';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-a4 .print-top .page-container{';
      style += 'position:absolute;';
      style += 'left:0;';
      style += 'top:0;';
      style += '}';
      style += '.print-wrapper-a4 td{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += 'vertical-align:top;';
      style += 'word-break:break-word;';
      style += 'padding:0 8px 0 0;';
      style += '}';
      style += '.print-wrapper-a4 td .size{';
      style += 'display:inline-block;';
      style += '}';
      style += '.print-wrapper-a4 td:first-child{';
      style += 'width:100px;';
      style += '}';
      style += '.print-wrapper-a4 td:last-child{';
      style += 'width:45px;';
      style += '}';
      style += '.print-wrapper-a4 .purchaser{';
      style += `font-size:${this.printConfig.a4.fontSize.name}px;`;
      style += '}';
      style += '.print-wrapper-a4 .item-no,.print-wrapper-a4 td .size{';
      style += `font-weight:600;`;
      style += '}';
      style += '.print-wrapper-a4 .total-num{';
      style += `font-size:${this.printConfig.a4.fontSize.statistics}px;`;
      style += '}';
      style += '</style>';
      return style;
    },
    setA4Page3(data, printTime, style, curPage = 1, preStoreName = '') {
      if (!data || data.length == 0) {
        return;
      }
      if (this.printConfig.orderByStoreGroup && preStoreName != data[0].StoreName) {
        preStoreName = data[0].StoreName;
      }
      if (curPage == 1) {
        printTime = printTime.split(' ')[0];
      }
      this.pageSize.a4.totalPage = curPage == 1 ? 1 : this.pageSize.a4.totalPage + 1;
      let hasNext = false;
      let $wrapper = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab} print-wrapper-${curPage}"></div>`);
      this.$(`${this.printViewID} .paper-a4`).append($wrapper);
      $wrapper = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage}`);
      $wrapper.append(style);
      $wrapper.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage} .print-content`);
      if (this.printConfig.a4.title) {
        $content.append(`<div class="title">${this.printConfig.a4.title}</div>`);
      }
      $content.append(`<div class="print-top"><span class="print-time">${printTime}</span>${preStoreName != '' ? `&nbsp;&nbsp;&nbsp;<span class="store-name">${preStoreName}</span>` : ''}&nbsp;&nbsp;<span class="page-container">${curPage}/<span class="total-page"></span><span></div>`);
      $content.append('<table></table>');
      let $table = $content.find('table');
      for (let i = 0; i < data.length; i++) {
        if (this.printConfig.orderByStoreGroup && data[i].StoreName != preStoreName) {
          hasNext = true;
          break;
        }
        let content = '';
        for (let j = 0; j < data[i].Items.length; j++) {
          let supplierStr = '';
          if (this.printConfig.showSupplier) {
            supplierStr = `<span style='font-weight:normal;'>(${data[i].Items[j].SupplierName || '仓库'})</span>`;
          }
          content += `${j > 0 ? ' ' : ''}<span class="item-no">${supplierStr}${data[i].Items[j].ItemNo}</span>`;
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            content += `${m > 0 ? ' ' : ''}<span class="color">${data[i].Items[j].Specs[m].Color}</span>`;
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let size = data[i].Items[j].Specs[m].Sizes[n].Size;
              let num = data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
              if (num > 1) {
                size += '*' + num;
              }
              content += `${n > 0 ? ' ' : ''}<span class="size">${size}</span>`;
            }
          }
        }
        let $tr = this.$(`<tr><td class="purchaser">${this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)}</td></tr>`);
        $tr.append(`<td>${content}${data[i].Remark ? '<br/>备注：' + data[i].Remark : ''}</td>`);
        $tr.append(`<td class="total-num">${data[i].Num - data[i].OutStockNum}</td>`);
        $table.append($tr);
        hasNext = this.checkOverA4Page($table, $content);
        if (hasNext) {
          break;
        }
        data.splice(i, 1);
        i--;
      }
      if (hasNext) {
        this.setA4Page3(data, printTime, style, curPage + 1, preStoreName);
      } else {
        this.$(`${this.printViewID} .paper-a4 .total-page`).text(this.pageSize.a4.totalPage);
        this.$emit('update:a4-total-page', this.pageSize.a4.totalPage);
      }
    },
    getA4Style4() {
      let style = '<style type="text/css">';
      style += '.print-wrapper-a4{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += `height:${this.pageSize.a4.pageHeight}px;`;
      style += `width:${this.pageSize.a4.pageWidth}px;`;
      style += 'background:white;';
      style += 'margin:5px;';
      style += 'page-break-after:always;';
      style += '}';
      style += '.print-wrapper-a4 .store-name{';
      style += `font-size:${this.printConfig.a4.fontSize.storeName}px;`;
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'width:calc(100% - 10px);';
      style += 'margin:0 5px;';
      style += 'padding:5px 0;';
      style += '}';
      style += '.print-wrapper-a4 .print-content > div,.print-wrapper-a4 .print-content table{';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-a4 .print-content{';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-a4 .title{';
      style += 'text-align:center;';
      style += 'font-size:16px;';
      style += '}';
      style += '.print-wrapper-a4 .print-top{';
      style += 'text-align:left;';
      style += 'font-size:15px;';
      style += 'margin-bottom:10px;';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-a4 .print-top .page-container{';
      style += 'position:absolute;';
      style += 'left:0;';
      style += 'top:0;';
      style += '}';
      style += '.print-wrapper-a4 .print-list{';
      style += `width: calc(50% - 10px) !important;`;
      style += 'display:inline-block;';
      style += 'vertical-align:top;';
      style += '}';
      style += '.print-wrapper-a4 .print-list+.print-list{';
      style += 'margin-left: 20px;';
      style += '}';
      style += '.print-wrapper-a4 .print-data{';
      style += 'display:block;';
      style += 'vertical-align:top;';
      style += 'margin-bottom: 10px;';
      style += '}';
      style += '.print-wrapper-a4 table{';
      style += `text-align:center;`;
      style += `border-collapse:collapse;`;
      style += `border:1px solid black;`;
      style += '}';
      style += '.print-wrapper-a4 table tr th,.print-wrapper-a4 table tr td{';
      style += `font-size:${this.printConfig.a4.fontSize.detail}px;`;
      style += 'font-weight:normal;';
      style += `border:1px solid black;`;
      style += 'word-break:break-word;';
      style += `width:54px;`;
      style += '}';
      style += '.print-wrapper-a4 .size{';
      style += `width:auto;`;
      style += `text-align:left;`;
      style += '}';
      style += '.print-wrapper-a4 .size span{';
      style += 'display:inline-block;';
      style += '}';
      style += '.print-wrapper-a4 .num{';
      style += `width:40px;`;
      style += '}';
      style += '.print-wrapper-a4 table tr th{';
      style += `text-align:center !important;`;
      style += '}';
      style += '.print-wrapper-a4 .total{';
      style += '&>span{';
      style += `font-size:${this.printConfig.a4.fontSize.statistics}px;`;
      style += `text-align:right;`;
      style += 'display:inline-block;';
      style += `width:50%;`;
      style += '}';
      style += '}';
      style += '.print-wrapper-a4 .remark{';
      style += `text-align:left !important;`;
      style += '}';
      style += '</style>';
      return style;
    },
    setA4Page4(data, printTime, style, curPage = 1, preStoreName = '') {
      if (!data || data.length == 0) {
        return;
      }
      if (this.printConfig.orderByStoreGroup && preStoreName != data[0].StoreName) {
        preStoreName = data[0].StoreName;
      }
      if (curPage == 1) {
        printTime = printTime.split(' ')[0];
      }
      this.pageSize.a4.totalPage = curPage == 1 ? 1 : this.pageSize.a4.totalPage + 1;
      let hasNext = false;
      let $wrapper = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab} print-wrapper-${curPage}"></div>`);
      this.$(`${this.printViewID} .paper-a4`).append($wrapper);
      $wrapper = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage}`);
      $wrapper.append(style);
      $wrapper.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-a4 .print-wrapper-${curPage} .print-content`);
      if (this.printConfig.a4.title) {
        $content.append(`<div class="title">${this.printConfig.a4.title}</div>`);
      }
      $content.append(`<div class="print-top">${curPage}/<span class="total-page"></span>&nbsp;&nbsp;&nbsp;<span class="print-time">${printTime}</span>${preStoreName != '' ? `&nbsp;&nbsp;&nbsp;<span class="store-name">${preStoreName}</span>` : ''}</div>`);
      let headerHeight = parseFloat($content.outerHeight(true));

      let $leftDiv = this.$(`<div class="print-list"></div>`);
      $content.append($leftDiv);
      this.appendDetails($leftDiv, data, headerHeight, preStoreName);

      if (data.length > 0 && (!this.printConfig.orderByStoreGroup || (this.printConfig.orderByStoreGroup && data[0].StoreName == preStoreName))) {
        let $rightDiv = this.$(`<div class="print-list"></div>`);
        $content.append($rightDiv);
        this.appendDetails($rightDiv, data, headerHeight, preStoreName);
      }
      if (data.length > 0) {
        hasNext = true;
      }

      if (hasNext) {
        this.setA4Page4(data, printTime, style, curPage + 1, preStoreName);
      } else {
        this.$(`${this.printViewID} .paper-a4 .total-page`).text(this.pageSize.a4.totalPage);
        this.$emit('update:a4-total-page', this.pageSize.a4.totalPage);
      }
    },
    appendDetails($listDiv, data, headerHeight, preStoreName) {
      //#region 表标题
      let $dataDiv = this.$(`<div class="print-data"></div>`);
      let $table = this.$('<table border="1" cellspacing="0" cellpadding="1"></table>');
      let $headerTr = this.$(`<tr><th>客户</th>${this.printConfig.showSupplier ? '<td>供应商</td>' : ''}<th>货号</th><th>颜色</th><th class="size">尺码</th><th class="num">数量</th></tr>`);
      if (this.printConfig.a4.showTotalFee) {
        $headerTr.append('<th>小计</th>');
      }
      $table.append($headerTr);
      $dataDiv.append($table);
      $listDiv.append($dataDiv);
      //#endregion

      let overHeight = false;
      for (let i = 0; i < data.length; i++) {
        if (this.printConfig.orderByStoreGroup && data[i].StoreName != preStoreName) {
          break;
        }
        let $dataDiv = this.$(`<div class="print-data"></div>`);
        $listDiv.append($dataDiv);
        let $table = this.$('<table border="1" cellspacing="0" cellpadding="1"></table>');
        $dataDiv.append($table);
        let purchaserName = this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick);

        let $purchaserTd = null;
        for (let j = 0; j < data[i].Items.length; j++) {
          let $supplierTd = null;
          let $itemTd = null;
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            let $tr = this.$('<tr></tr>');
            let $td = this.$(`<td>${purchaserName}</td>`);
            if (!$purchaserTd) {
              $purchaserTd = $td;
            }
            else {
              $purchaserTd[0].rowSpan += 1;
              $td[0].style.display = "none";
            }
            $tr.append($td);
            if (this.printConfig.showSupplier) {
              $td = this.$(`<td>${data[i].Items[j].SupplierName || '仓库'}</td>`);
              if (!$supplierTd) {
                $supplierTd = $td;
              }
              else {
                $supplierTd[0].rowSpan += 1;
                $td[0].style.display = "none";
              }
              $tr.append($td);
            }
            $td = this.$(`<td>${data[i].Items[j].ItemNo}</td>`);
            if (!$itemTd) {
              $itemTd = $td;
            }
            else {
              $itemTd[0].rowSpan += 1;
              $td[0].style.display = "none";
            }
            $tr.append($td);
            $tr.append(`<td>${data[i].Items[j].Specs[m].Color}</td>`);
            let sizeContents = [];
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let size = data[i].Items[j].Specs[m].Sizes[n].Size;
              let num = data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
              if (num != 0) {
                sizeContents.push(`<span>${size}*${num}</span>`);
              }
            }
            $tr.append(`<td class="size">${sizeContents.join(' ')}</td>`);
            $tr.append(`<td class="num">${data[i].Items[j].Specs[m].Num - data[i].Items[j].Specs[m].OutStockNum}</td>`);
            if (this.printConfig.a4.showTotalFee) {
              $tr.append(`<td>${data[i].Items[j].Specs[m].Payment}</td>`);
            }
            $table.append($tr);
            overHeight = this.checkOverA4Page($table, $listDiv, headerHeight + (j == data[i].Items.length - 1 && m == data[i].Items[j].Specs.length - 1 ? 20 : 0));
            if (overHeight) {
              break;
            }
            data[i].Items[j].Specs.splice(m, 1);
            m--;
          }
          if (overHeight) {
            break;
          }
          data[i].Items.splice(j, 1);
          j--;
        }
        if (overHeight) {
          break;
        }
        let $total = this.$('<div class="total"></div>');
        $total.append(`<span class="remark">${data[i].Remark ? '备注：' : ''}${data[i].Remark}</span>`);
        $total.append(`<span>合计：${(this.printConfig.a4.showTotalFee ? `${data[i].Payment}元，` : '')}${data[i].Num - data[i].OutStockNum}件</span>`);
        $dataDiv.append($total);
        data.splice(i, 1);
        i--;
      }
    },
    checkOverA4Page($table, $content, extendHeight = 0) {
      let hasNext = false;
      let pageHeight = parseFloat($content.outerHeight(true));
      if (this.pageSize.a4.pageHeight - 10 - extendHeight - pageHeight <= 0) {
        $table.find('tr:last').remove();
        hasNext = true;
      }
      return hasNext;
    },
    getTagStyle() {
      let style = '<style type="text/css">';
      style += '.print-wrapper-tag{';
      style += `height:${this.pageSize.tag.pageHeight}px;`;
      style += `width:${this.pageSize.tag.pageWidth}px;`;
      style += 'background:white;';
      style += 'padding:0;';
      style += 'page-break-after:always;';
      style += 'margin:5px;';
      style += '}';
      style += '.print-wrapper-tag .print-content{';
      style += 'height:100%;';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-tag .print-content > div{';
      style += 'width:100%;';
      style += '}';
      style += '.print-wrapper-tag .purchaser{';
      style += `font-size:${this.printConfig.tag.fontSize.name}px;`;
      style += `line-height:${this.printConfig.tag.fontSize.name}px;`;
      style += `text-align:${this.printConfig.tag.nameAlignCenter ? 'center' : 'left'};`;
      style += 'padding:0;';
      style += 'font-weight:600;';
      style += 'overflow:hidden;';
      style += 'text-overflow:ellipsis;';
      if (this.printConfig.tag.showDetail || this.printConfig.tag.barcodePosition == 'bottom') {
        style += 'white-space:nowrap;';
      }
      else {
        style += 'display: -webkit-box;';
        style += '-webkit-box-orient: vertical;';
        style += '-webkit-line-clamp: 2;';
      }
      style += '}';
      style += '.print-wrapper-tag .item{';
      style += 'word-break:break-all;';
      style += 'font-size:14px;';
      style += '}';
      style += '.print-wrapper-tag .item table{';
      style += 'border-collapse:collapse;';
      style += 'height:100%;';
      style += 'padding:0;';
      style += 'margin:0;';
      style += 'vertical-align:middle;';
      style += '}';
      style += '.print-wrapper-tag .item table td{';
      style += 'padding:0;';
      style += 'margin:0;';
      style += `font-size:${this.printConfig.tag.fontSize.detail}px;`;
      style += `line-height:${this.printConfig.tag.fontSize.detail + 1}px;`;
      style += '}';
      style += '.print-wrapper-tag .item table td span{';
      style += 'display:inline-block;';
      style += '}';
      style += '.print-wrapper-tag .barcode{';
      style += 'text-align:center;';
      style += '}';
      style += '.print-wrapper-tag .remark{';
      style += `font-size:${this.printConfig.tag.fontSize.remark}px;`;
      style += `line-height:${this.printConfig.tag.fontSize.remark + 1}px;`;
      style += 'overflow:hidden;';
      style += 'text-overflow:ellipsis;';
      style += 'white-space:nowrap;';
      style += '}';
      style += '.print-wrapper-tag .statistics{';
      style += `font-size:${this.printConfig.tag.fontSize.statistics}px;`;
      style += `line-height:${this.printConfig.tag.fontSize.statistics}px;`;
      style += 'font-weight:600;';
      style += 'text-align:right;';
      style += 'box-sizing:border-box;';
      style += 'padding-right:5px;';
      style += 'position:relative;';
      style += '}';
      style += '.print-wrapper-tag .statistics.no-detail{';
      style += 'text-align:left;';
      style += 'padding-left:5px;';
      style += `margin-top:${(this.printConfig.tag.barcodePosition == 'bottom' ? '0' : '10')}px;`;
      style += '&>span{';
      style += 'margin-bottom:10px;';
      style += '}';
      style += '.total{';
      style += 'position:absolute;';
      style += 'right:5px;';
      style += '}';
      style += '}';
      style += '.print-wrapper-tag .statistics span.page-info{';
      style += 'position:absolute;';
      style += 'top:0;';
      style += 'left:0;';
      style += 'display:inline-block;';
      style += '}';
      style += '</style>';
      return style;
    },
    setTagPage(data, style, curPage = 1, page = 1) {
      if (!data || data.length == 0) {
        return;
      }
      if (curPage > 1 && !this.$(`${this.printViewID} .paper-tag .print-wrapper-${curPage - 1} .print-content td div`).text()) {
        return;
      }
      let hasNext = false;
      let nextPage = page + 1;
      let $page = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab} print-wrapper-${curPage}"></div>`);
      this.$(`${this.printViewID} .paper-tag`).append($page);
      $page = this.$(`${this.printViewID} .paper-tag .print-wrapper-${curPage}`);
      $page.append(style);
      $page.append('<div class="print-content"></div>');
      let $content = this.$(`${this.printViewID} .paper-tag .print-wrapper-${curPage} .print-content`);
      let itemContent = '';
      let tempItemContent = '';
      for (let i = 0; i < data.length; i++) {
        $content.append(`<div class="purchaser">${this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)}</div>`);
        $content.append('<div class="item"><table><tr><td><div></div></td></tr></table></div>');
        $content.append(`<div class="remark">${page == 1 && this.printConfig.tag.showRemark && data[i].Remark ? `备注：${data[i].Remark}` : ''}</div>`);
        $content.append('<div class="statistics"><span class="page-info"></span><span>(件)</span></div>');
        let $div = $content.find('.item');
        let divHeight = $div.parent().height() - $div.siblings('.purchaser').height() - $div.siblings('.remark').height() - $div.siblings('.statistics').height();
        $div.css('height', divHeight + 'px');
        let itemIndex = 0;
        for (let j = 0; j < data[i].Items.length; j++) {
          if (itemIndex++ != 0 && this.printConfig.tag.wrapByItemNo) {
            tempItemContent = itemContent + '<br/>';
            $div.find('td div').html(tempItemContent);
            hasNext = this.checkOverTagItemHeight($div, divHeight, true);
            if (hasNext) {
              $div.find('td div').html(itemContent);
              break;
            }
            itemContent = tempItemContent;
          }
          tempItemContent = itemContent + data[i].Items[j].ItemNo;
          $div.find('td div').html(tempItemContent);
          hasNext = this.checkOverTagItemHeight($div, divHeight);
          if (hasNext) {
            $div.find('td div').html(itemContent);
            break;
          }
          itemContent = tempItemContent;
          for (let m = 0; m < data[i].Items[j].Specs.length; m++) {
            tempItemContent = itemContent + data[i].Items[j].Specs[m].Color;
            $div.find('td div').html(tempItemContent);
            hasNext = this.checkOverTagItemHeight($div, divHeight);
            if (hasNext) {
              $div.find('td div').html(itemContent);
              break;
            }
            itemContent = tempItemContent;
            $div.find('td div').html(itemContent);
            for (let n = 0; n < data[i].Items[j].Specs[m].Sizes.length; n++) {
              let size = data[i].Items[j].Specs[m].Sizes[n].Size ? data[i].Items[j].Specs[m].Sizes[n].Size : '';
              let num = data[i].Items[j].Specs[m].Sizes[n].Num - data[i].Items[j].Specs[m].Sizes[n].OutStockNum;
              tempItemContent = itemContent + `<span>${size}${num > 1 ? (size ? '*' : '') + num : ''}${(n == data[i].Items[j].Specs[m].Sizes.length - 1 ? ';' : ',')}</span>`;
              $div.find('td div').html(tempItemContent);
              hasNext = this.checkOverTagItemHeight($div, divHeight);
              if (hasNext) {
                if (itemContent.slice(-data[i].Items[j].Specs[m].Color.length) == data[i].Items[j].Specs[m].Color) {
                  itemContent = itemContent.substring(0, itemContent.lastIndexOf(data[i].Items[j].Specs[m].Color));
                }
                if (itemContent.slice(-data[i].Items[j].ItemNo.length) == data[i].Items[j].ItemNo) {
                  itemContent = itemContent.substring(0, itemContent.lastIndexOf(data[i].Items[j].ItemNo));
                }
                $div.find('td div').html(itemContent);
                break;
              }
              itemContent = tempItemContent;
              data[i].Items[j].Specs[m].Sizes.splice(n, 1);
              n--;
            }
            if (data[i].Items[j].Specs[m].Sizes.length == 0) {
              data[i].Items[j].Specs.splice(m, 1);
              m--;
            }
            if (hasNext) {
              break;
            }
          }
          if (data[i].Items[j].Specs.length == 0) {
            data[i].Items.splice(j, 1);
            j--;
          }
          if (hasNext) {
            break;
          }
        }
        let $span = this.$('<span></span>');
        if (this.printConfig.tag.totalAmountType != 'none') {
          $span.append('总金额:' + (this.printConfig.tag.totalAmountType == 'price' ? data[i].TotalFee : data[i].Payment));
        }
        $span.append(`(${data[i].Num - data[i].OutStockNum}件)`);
        $div.siblings('.statistics').empty().append(`<span class="page-info"><strong class="statistics-pages">${page}/</strong></span>`).append($span);
        if (data[i].Items.length == 0) {
          data.splice(i, 1);
          i--;
          nextPage = 1;
        }
        hasNext = true;
        break;
      }
      if (nextPage == 1) {
        for (let i = page - 1; i >= 0; i--) {
          let $totalPages = this.$(`${this.printViewID} .print-wrapper-` + (curPage - i) + ' .statistics-pages');
          if (page > 1) {
            $totalPages.append(page);
          } else {
            $totalPages.text('');
          }
        }
      }
      if (hasNext) {
        this.setTagPage(data, style, curPage + 1, nextPage);
      }
    },
    setTagPageNoDetail(data, style) {
      if (!data || data.length == 0) {
        return;
      }
      for (let i = 0; i < data.length; i++) {
        let $page = this.$(`<div class="print-wrapper print-wrapper-${this.printViewTab} print-wrapper-${i + 1}"></div>`);
        $page.append(style);
        let $content = this.$('<div class="print-content"></div>');
        $content.append(`<div class="purchaser">${this.getPurchaserName(data[i].PurchaserName, data[i].PurchaserNick)}</div>`);
        let $statistics = this.$('<div class="statistics no-detail"></div>');

        if (data[i].Barcode && this.printConfig.tag.barcodePosition == 'bottom') {
          $content.append(this.$(`<div class="barcode"><img id="barcode-${data[i].Barcode}"/></div>`));
          $statistics.append(`<span class="barcode">${data[i].Barcode.substring(0, 8)}</span>`);

          let $span = this.$('<span class="total"></span>');
          if (this.printConfig.tag.totalAmountType != 'none') {
            $span.append('￥' + (this.printConfig.tag.totalAmountType == 'price' ? data[i].TotalFee : data[i].Payment));
          }
          $span.append(`(${data[i].Num - data[i].OutStockNum})`);
          $statistics.append($span);
        }
        else {
          if (this.printConfig.tag.totalAmountType != 'none') {
            $statistics.append(`<span>${'总金额:' + (this.printConfig.tag.totalAmountType == 'price' ? data[i].TotalFee : data[i].Payment)}</span><br/>`);
          }
          $statistics.append(`<span>总件数:${data[i].Num - data[i].OutStockNum}</span>`);
        }
        $content.append($statistics);
        $page.append($content);

        this.$(`${this.printViewID} .paper-tag`).append($page);
        $content = this.$(`.print-wrapper print-wrapper-${this.printViewTab} print-wrapper-${i + 1} .print-content`);
        let divHeight = $content.height() - $content.find('.purchaser').height();
        if (data[i].Barcode && this.printConfig.tag.barcodePosition == 'bottom') {
          JsBarcode('#barcode-' + data[i].Barcode, data[i].Barcode, { displayValue: false, width: 1, height: 40, margin: 5 });
          divHeight -= $content.find('.barcode').height();
        }
        $content.find('.statistics').css('height', divHeight + 'px');
      }
    },
    checkOverTagItemHeight($div, divHeight, isDivider = false) {
      let hasNext = false;
      if ($div.find('td div')[0].clientHeight > divHeight) {
        if (isDivider) {
          $div.find('td br:last').remove();
        } else {
          $div.find('td span:last').remove();
        }
        hasNext = true;
      }
      return hasNext;
    },
    getPurchaserName(name, nick) {
      switch (this.printConfig.showPurchaserType) {
        case 'name':
          return name;
        case 'nick':
          return nick ? nick : name;
        default:
          return (nick ? `(${nick})` : '') + name;
      }
    },
    async printInventory() {
      this.printTime = getDateTime();
      let shortPrintTime = this.printTime.substring(0, this.printTime.lastIndexOf(':'));
      this.$(`${this.printViewID} .print-time`).text(shortPrintTime);
      this.windowPrint();
    },
    async printAllocate() {
      this.printTime = getDateTime();
      let shortPrintTime = this.printTime.substring(0, this.printTime.lastIndexOf(':'));
      this.$(`${this.printViewID} .print-time`).text(shortPrintTime);
      this.windowPrint();
    },
    async print(tradeIDs, status, refundIDs = '', tradeInfo = null) {
      if (!tradeIDs && !refundIDs) {
        this.$baseMessage('请选择报单', 'error');
        return;
      }
      this.printTime = getDateTime();
      let shortPrintTime = this.printTime.substring(0, this.printTime.lastIndexOf(':'));
      if (this.printViewTab == 'a4' && (this.printConfig.a4.printType == '2' || this.printConfig.a4.printType == '3')) {
        shortPrintTime = shortPrintTime.split(' ')[0];
      }
      this.$(`${this.printViewID} .print-time`).text(shortPrintTime);
      this.windowPrint(this.savePrint, { tradeIDs: tradeIDs, status: status, printTime: this.printTime, tradeInfo: tradeInfo });
    },
    async windowPrint(callback = null, callbackParam = null) {
      let $paper = this.$(`${this.printViewID} .paper-${this.printViewTab}`);
      if ($paper.length == 0) {
        this.$baseMessage('打印内容为空', 'error');
        return;
      }
      let $printPaper = $paper.clone();
      if (this.statusTab == 'waitCheckStock') {
        if (this.printViewTab == 'bill') {
          $printPaper.find('.out-stock-wrapper').css('display', 'none');
        } else {
          $printPaper.find('.out-stock-wrapper').css('visibility', 'hidden');
          $printPaper.find('.out-stock-wrapper').css('visibility', 'hidden');
          $printPaper.find('.total-out-num').css('visibility', 'hidden');
        }
      }
      $printPaper.find(`.print-wrapper-${this.printViewTab}`).css('margin', '0');
      const iframe = document.createElement('iframe');
      iframe.style = 'position:absolute;top:0,left:0;z-index:-1;display:none;';
      let doc = null;
      iframe.id = 'window-print';
      document.body.appendChild(iframe);
      doc = iframe.contentWindow.document;
      doc.write('<head></head>' + `<body>${$printPaper.html()}</body>`);
      doc.close();
      iframe.contentWindow.focus();
      if (this.printSource == 'print' && callback) {
        let _this = this;
        iframe.contentWindow.onafterprint = function () {
          if (_this.printConfig.checkPrintResult && _this.statusTab == 'waitPrint') {
            _this.$confirm('是否打印完成？', '提示', {
              confirmButtonText: '完成',
              cancelButtonText: '否',
              type: 'warning'
            }).then(() => {
              callback(callbackParam.tradeIDs, callbackParam.status, callbackParam.printTime, true, callbackParam.tradeInfo);
            }).catch(() => { });
          }
          else {
            callback(callbackParam.tradeIDs, callbackParam.status, callbackParam.printTime, true, callbackParam.tradeInfo);
          }
        };
      }
      iframe.contentWindow.print();
      this.$('#window-print').remove();
    },
    async savePrint(tradeIDs, status, printTime = null, hasPrint = false, tradeInfo = null) {
      if (!tradeIDs) {
        this.$baseMessage('请选择报单', 'error');
        return;
      }
      if (!printTime) {
        this.printTime = getDateTime();
        let shortPrintTime = this.printTime.substring(0, this.printTime.lastIndexOf(':'));
        this.$(`${this.printViewID} .print-time`).text(shortPrintTime);
      }
      await submit(status == 1 && this.printConfig.withSupplierGroup ? '/api/stall/printForSupplierGroup' : '/api/stall/print', {
        tradeIDs: tradeIDs,
        status: status,
        printTime: this.printTime,
        hasPrint: hasPrint,
        skipCheckStock: this.printConfig.skipCheckStock,
        tradeInfo: status == 1 && this.printConfig.withSupplierGroup ? tradeInfo : null,
      });
      this.$baseMessage('打印成功', 'success');
      if (this.statusTab == 'waitPrint') {
        this.$emit('savePrintSuccess');
      }
    },
    async sendBill() {
      let elements = document.getElementsByClassName('print-data');
      let result = true;
      if (elements.length > 0) {
        for (let i = 0; i < elements.length; i++) {
          if (!elements[i].attributes['bot-uid'].value || !elements[i].attributes['sender-uid'].value) {
            continue;
          }
          let dataUrl = await screenshotToBase64(elements[i]);
          let res = await botSend("img", dataUrl, {
            botType: Number.parseInt(elements[i].attributes['bot-type'].value),
            botUid: elements[i].attributes['bot-uid'].value,
            senderUid: elements[i].attributes['sender-uid'].value
          });
          if (!res) {
            result = false;
          }
        }
        if (result) {
          this.$message({ type: 'success', message: '发送成功' });
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
